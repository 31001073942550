import styled from "styled-components";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
//import clearSessionStorage from '../../../../utils/clearSessionStorage';
import { resetStyle } from "../../../../utils/resizeChat";

// Modal background layer - visible or invisible, but always floating above client's elements
const ChatCloseModal = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: ${({ show }) => (show ? "block !important" : "none !important")};
  background: rgba(0, 0, 0, 0.5);

  @media screen and (min-width: 600px) {
    right: 35px;
    bottom: 35px;
    width: 320px;
    height: 553px;
    max-height: 95%;
    z-index: 10000;
    border-radius: 8px 8px 0px 0px;
  }
`;

// Rendered popup - a positional demo confirmation box
const Container = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: calc(100% - 53px);
  background: rgb(242, 242, 245);
  padding: 1rem;
  color: rgba(0, 0, 139, 0.9);

  @media screen and (min-width: 600px) {
    border-radius: 8px 8px 0px 0px;
    right: 35px;
    bottom: 35px;
    width: 320px;
    height: 83%;
    z-index: 10000;
    max-height: 553px;
  }
`;

const YesButton = styled.button`
  width: 100%;
  cursor: pointer;
  position: relative;
  background: #235ae4;
  color: #fff;
  font-size: 14px;
  border-color: rgb(0, 0, 255);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 10px 40px;
  transition: all 0.2s linear 0s;
  height: 40px;
  &:focus {
    outline-offset: 0.1rem !important;
    outline: 3px solid #61a0f7;

    outline-radius: 5px !important;
  }
`;

const NoButton = styled.button`
  width: 100%;
  cursor: pointer;
  position: relative;
  background: transparent;
  color: rgb(0, 0, 255);
  font-size: 14px;
  border-color: rgb(0, 0, 255);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 10px 40px;
  transition: all 0.2s linear 0s;
  height: 40px;
  margin-top: 24px;
  &:focus {
    outline-offset: 0.1rem !important;
    outline: 3px solid #61a0f7;
  }
`;

const Header = styled.div`
  text-align: center;
  font-weight: 400;
  color: rgb(44, 44, 44);
  font-size: 20px;
  line-height: 30px;
  margin-top: 46px;
  margin-left: 11px;
  margin-right: 11px;
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: center;
  margin-top: 66px;
`;

const Slot = styled.div`
  font-size: 1.6rem;
  color: inherit;
`;

export default function ConfirmationModalImpl(props) {
  const {
    handleModalClose, // renderProp fn returns true or false
    show, // boolean - visible/invisible
    headerText, // text
    detailText, // html / inner text
    openPos, // symbol for placement
  } = { ...props };

  useEffect(() => {
    const firstElement = document.getElementById("yesLeaveChat");
    const lastElement = document.getElementById("noKeepChatting");
    function chatCloseModalKeyListener(e) {
      if (show) {
        const listener = keyListenersMap.get(e.keyCode);
        return listener && listener(e, firstElement, lastElement);
      }
    }
    if (show) {
      document.addEventListener("keydown", chatCloseModalKeyListener);
      const noKeepChatting = document.getElementById("noKeepChatting");
      if (noKeepChatting) {
        noKeepChatting.focus();
      }
    }

    return () => document.removeEventListener("keydown", chatCloseModalKeyListener);
  });

  const handleTabKey = (e, firstElement, lastElement) => {
    if (!e.shiftKey && document.activeElement !== firstElement) {
      firstElement.focus();
      return e.preventDefault();
    }

    if (e.shiftKey && document.activeElement !== lastElement) {
      lastElement.focus();
      e.preventDefault();
    }
  };
  const handleChatClose2 = () => {
    resetStyle();
    const chatEndEvent = new CustomEvent("CHAT_ACTIVITY", {
      bubbles: true,
      detail: { event: "CHAT_END_CLICK" },
    });
    window.dispatchEvent(chatEndEvent);
    console.log("Calling clearSessionStorage from ConfirmationModalImpl.js  	handleChatClose2() ");
    window.onEndChat();
    //clearSessionStorage();
  };

  const keyListenersMap = new Map([
    [9, handleTabKey],
    [27, handleModalClose],
  ]);

  return (
    <ChatCloseModal
      role="dialog"
      tabIndex={-1}
      aria-describedby="close-modal-text"
      aria-modal="true"
      aria-hidden="false"
      show={show}
      className={`connect-customer-interface`}
    >
      <Container openPos={openPos} className={`end-chat-overlay`}>
        <Header id="close-modal-text" aria-label={headerText}>
          {headerText}
        </Header>
        <Slot>{detailText}</Slot>
        <ButtonBar>
          <table style={{ textAlign: "center" }}>
            <tbody>
              <tr>
                <td>
                  <YesButton
                    tabIndex={0}
                    aria-label="Yes, leave chat"
                    id="yesLeaveChat"
                    className="yesBtn chatFonts"
                    onClick={handleChatClose2}
                    primary={true}
                  >
                    Yes, leave chat
                  </YesButton>
                  <NoButton
                    tabIndex={0}
                    aria-label="No, keep chatting"
                    id="noKeepChatting"
                    className="noBtn chatFonts"
                    onClick={handleModalClose}
                    primary={false}
                  >
                    No, keep chatting
                  </NoButton>
                </td>
              </tr>
            </tbody>
          </table>
        </ButtonBar>
      </Container>
    </ChatCloseModal>
  );
}
ConfirmationModalImpl.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  detailText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  openPos: PropTypes.symbol.isRequired,
};
