// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

//import 'core-js';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import activateChat from "./utils/activateChat";
import resumeChat from "./utils/resumeChat";
import defaultTheme from "./theme/defaultTheme";
import chatAvailability from "./utils/chatAvailability";
import checkChatStatus from "./utils/checkChatStatus";
import checkChatStatusAsync from "./utils/checkChatStatusAsync";
import minimizeChat from "./utils/minimizeChat";
import maximizeChat from "./utils/maximizeChat";
import closeChatSession from "./utils/closeChat";
import inviteToChat from "./utils/inviteToChat";
import updateChatSession from "./utils/updateChatSession";
import resizeChat from "./utils/resizeChat";
import contextEventHandler from "./utils/contextEventHandler";
(function (connect) {
  connect.ChatInterface = connect.ChatInterface || {};
  connect.ChatInterface.resume = ({ containerId, ...props }) => {
    console.log("containerId ", containerId);

    ReactDOM.render(
      <BrowserRouter>
        <App {...props} />
      </BrowserRouter>,
      document.getElementById(containerId) || document.getElementById("root")
    );
  };

  connect.ChatInterface.init = ({ containerId, ...props }) => {
    console.log("initiating chatInterface");

    ReactDOM.render(
      <BrowserRouter>
        <App {...props} />
      </BrowserRouter>,
      document.getElementById(containerId) || document.getElementById("root")
    );
  };

  connect.ChatInterface.getCurrentTheme = () => {
    return defaultTheme;
  };
  connect.version = () => {
    return "3.0.0";
  };

  connect.activateChat = activateChat;
  connect.resumeChat = resumeChat;
  connect.chatAvailability = chatAvailability;
  connect.checkChatStatus = checkChatStatus;
  connect.checkChatStatusAsync = checkChatStatusAsync;
  connect.minimizeChat = minimizeChat;
  connect.maximizeChat = maximizeChat;
  connect.closeChatSession = closeChatSession;
  connect.inviteToChat = inviteToChat;
  connect.updateChatSession = updateChatSession;
  window.closeChatSession = connect.closeChatSession;
  window.connect = connect;
  connect.resizeChat = resizeChat;

  window.contextEventHandler = contextEventHandler;

  // temp
  //window.connect.ChatSession.init('root')
})(window.connect || {});
