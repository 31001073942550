import styled from "styled-components";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { configuration } from "../../utils/configuration";

// Modal background layer - visible or invisible, but always floating above client's elements
const Model = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? "block" : "none")};
  border-radius: 0 0 0 0;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000;

  @media screen and (min-width: 600px) {
    bottom: 35px;
    right: 35px;
    width: 320px;
    height: 553px;
    max-height: 95%;
    border-radius: 8px 8px 0px 0px;
    z-index: 10000;
  }
`;

// Rendered popup - a positional demo confirmation box
const Container = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background: rgb(242, 242, 245);
  color: rgba(0, 0, 139, 0.9);
  z-index: 10000;

  @media screen and (min-width: 600px) {
    right: 0px;
    bottom: 0px;
    width: 320px;
    height: 553px;
    max-height: 95%;
    border-radius: 8px 8px 0px 0px;
    z-index: 10000;
  }
`;

const EndChatButton = styled.button`
  width: 100%;
  cursor: pointer;
  position: relative;
  background: transparent;
  color: rgb(0, 0, 255);
  font-size: 14px;
  border-color: rgb(0, 0, 255);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 10px 40px;
  transition: all 0.2s linear 0s;
  &:focus {
    outline-offset: 0.1rem !important;
    outline: 3px solid #61a0f7;

    outline-radius: 5px !important;
  }
`;

const CautionSymbol = styled.span`
  font-size: 1.5em;
  cursor: pointer;
  position: absolute;
  margin-top: 102.3px;
  margin-left: 24.25px;
  display: block;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 900;
  color: #fff;
  font-size: 18px;
  font-family: "Circular-USB", sans-serif;
  line-height: 26px;
  margin: 0px;
  padding: 10px;
  background: #235ae4;
  @media screen and (min-width: 600px) {
    height: 53px;
    border-radius: 8px 8px 0px 0px;
    z-index: 10000 !important;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: flex-end;
  margin: 38px 15px 0 15px;
`;

const Slot = styled.div`
  font-size: 1.6rem;
  color: #555555;
  margin-top: 102.3px;
  margin-left: 41.74px;
  margin-right: 41.74px;
  padding-left: 8px;
  padding-right: 7px;
  text-align: center;
  font-family: "Circular-USB", sans-serif;
`;

const SlotSecondary = styled.div`
  font-size: 1rem;
  color: #2c2c2c;
  margin: 8px 19px 0px 16px;
  text-align: center;
  font-family: "Circular-USB", sans-serif;
`;

export default function ChatError(props) {
  const {
    handleModalClose,
    headerText,
    detailText,
    secondaryText,
    openPos, // symbol for placement
  } = { ...props };

  const cautionUrl = configuration.baseUrl + "icons/caution.png";

  useEffect(() => {
    const firstElement = document.getElementById("end-chat-error");
    if (firstElement) {
      firstElement.focus();
    }
    function chatErrorModalKeyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e, firstElement, firstElement);
    }

    document.addEventListener("keydown", chatErrorModalKeyListener);

    return () => document.removeEventListener("keydown", chatErrorModalKeyListener);
  });

  const handleTabKey = (e, firstElement, lastElement) => {
    if (!e.shiftKey && document.activeElement !== firstElement) {
      firstElement.focus();
      return e.preventDefault();
    }

    if (e.shiftKey && document.activeElement !== lastElement) {
      lastElement.focus();
      e.preventDefault();
    }
  };

  const keyListenersMap = new Map([
    [9, handleTabKey],
    [27, handleModalClose],
  ]);

  return (
    <Model
      aria-live="polite"
      role="dialog"
      tabIndex={0}
      aria-describedby="chat-error-text"
      aria-modal="true"
      aria-hidden="false"
      show={true}
    >
      <Container openPos={openPos} className="connect-customer-interface">
        <Header className="headerWrapper">{headerText}</Header>
        <CautionSymbol>
          <img style={{ width: "15px" }} alt="down" src={cautionUrl} />
        </CautionSymbol>
        <Slot id="chat-error-text" arial-label={detailText}>
          {detailText}
        </Slot>
        <SlotSecondary>{secondaryText}</SlotSecondary>
        <ButtonBar>
          <table style={{ height: "100%", width: "100%", textAlign: "center" }}>
            <tbody>
              <tr>
                <td>
                  <EndChatButton
                    id="end-chat-error"
                    tabIndex={0}
                    aria-label="end chat"
                    className="noBtn chatFonts"
                    onClick={handleModalClose}
                    primary={false}
                  >
                    End chat
                  </EndChatButton>
                </td>
              </tr>
            </tbody>
          </table>
        </ButtonBar>
      </Container>
    </Model>
  );
}
ChatError.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  detailText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};
