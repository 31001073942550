// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { PureComponent } from "react";
import styled from "styled-components";
import PT from "prop-types";
import Linkify from "react-linkify";
import { ATTACHMENT_MESSAGE, AttachmentStatus, ContentType, Status, Direction } from "../../datamodel/Model";
import { Icon, TypingLoader } from "connect-core";
import { InteractiveMessage } from "./InteractiveMessage";
import { SESSION_CUSTOMER_INFO } from "../../../../utils/constants";
import { isEmptyObject } from "../../../../utils/commonUtils";
const getMessageType = (props) => {
  return (
    props.children.props &&
    props.children.props.messageDetails &&
    props.children.props.messageDetails.content &&
    props.children.props.messageDetails.content.type
  );
};
export const MessageBox = styled.div`
	word-break: break-word;
	white-space: pre-line;
	overflow: auto;
	text-align: ${(props) => props.textAlign};
	&:focus {
		outline-offset: -0.1rem !important;
		outline: 3px solid #61a0f7;
	}
	margin-left: ${(props) => (props.direction === Direction.Outgoing ? "40px" : "16px")}
	margin-right: ${(props) =>
    props.direction === Direction.Incoming &&
    getMessageType(props) !== ContentType.EVENT_CONTENT_TYPE.PARTICIPANT_JOINED
      ? "40px"
      : "16px"}
    margin-top: 18px;
	font-size: 14px;
	margin-bottom: ${(props) => (props.isLatestMessage ? "18px" : "0px")}
  font-family: "Circular-USB", sans-serif;
`;
const Header = styled.div`
  overflow: auto;
`;
Header.Sender = styled.div`
  float: left;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
Header.Status = styled.div`
  float: right;
`;
const Body = styled.div`
  ${(props) =>
    props.direction === Direction.Outgoing
      ? "background: rgb(200, 226, 249) !important"
      : props.theme.chatTranscriptor.incomingMsg};

  ${(props) => (props.messageStyle ? props.messageStyle : "")};

  padding: ${(props) => (props.removePadding ? 0 : props.theme.spacing.base)};
  margin-top: ${(props) => props.theme.spacing.mini};
  border-radius: 5px;
  position: relative;
`;
const ErrorText = styled.div`
  color: ${({ theme }) => theme.palette.red};
  display: flex;
  > img {
    margin-right: ${({ theme }) => theme.spacing.mini};
  }
`;
const StatusText = styled.span`
  color: ${({ theme }) => theme.globals.textSecondaryColor};
  padding-right: ${({ theme }) => theme.spacing.mini};
`;

const TransportErrorMessage = styled.div`
  margin-left: ${(props) => props.theme.chatTranscriptor.msgStatusWidth};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.small}
    ${({ theme }) => theme.spacing.micro};

  span {
    color: ${({ theme }) => theme.palette.red};
  }
  &:focus {
    outline-offset: -0.1rem !important;
    outline: 3px solid #61a0f7;
  }
`;

TransportErrorMessage.RetryButton = styled.a`
  margin-left: ${({ theme }) => theme.spacing.micro};
  &:focus {
    outline-offset: -0.1rem !important;
    outline: 3px solid #61a0f7;
  }
`;

export class ParticipantMessage extends PureComponent {
  static propTypes = {
    messageDetails: PT.object.isRequired,
    incomingMsgStyle: PT.object,
    outgoingMsgStyle: PT.object,
    mediaOperations: PT.object,
    isLatestMessage: PT.bool,
  };

  timestampToDisplayable(timestamp) {
    const d = new Date(0);
    d.setUTCSeconds(timestamp);
    return d.toLocaleTimeString([], { hour: "numeric", minute: "numeric" });
  }

  renderHeader() {
    let displayName = this.props.messageDetails.displayName;
    const customInfoStore = sessionStorage.getItem(SESSION_CUSTOMER_INFO);
    const customerInfo = JSON.parse(customInfoStore || "{}");
    if (displayName && (displayName === "SYSTEM_MESSAGE" || displayName === "BOT")) {
      //displayName = 'U.S. Bank';
      //displayName = 'Agent';
      //https://www.figma.com/file/LFYKDsJwfM6zYyvk9fl2wC/OLB-Live-Chat?type=design&t=bLEgMzcrV35uJ6R2-0
      let partnerId = "";
      partnerId = !isEmptyObject(customerInfo)
        ? (customerInfo.partnerId || customerInfo.auxData.partnerId || "").replace(/([^\w]+|\s+)/g, "").toLowerCase()
        : "";
      displayName = partnerId === "usbank" ? "U.S. Bank" : "System";
    }
    const transportDetails = this.props.messageDetails.transportDetails;
    const isOutgoingMsg = this.props.messageDetails.transportDetails.direction === Direction.Outgoing;
    let transportStatusElement = <React.Fragment />;
    switch (transportDetails.status) {
      case Status.Sending:
        transportStatusElement = (
          <React.Fragment>
            <StatusText>
              <span>Sending</span>
            </StatusText>
          </React.Fragment>
        );
        break;
      case Status.SendSuccess:
        transportStatusElement = (
          <React.Fragment>
            {isOutgoingMsg && (
              <StatusText>
                <span>Sent at</span>
              </StatusText>
            )}
            {this.timestampToDisplayable(transportDetails.sentTime)}
          </React.Fragment>
        );
        break;
      case Status.SendFailed:
        transportStatusElement = (
          <ErrorText>
            <Icon />
            <span aria-label="Failed to send">Failed to send!</span>
          </ErrorText>
        );
        break;
      default:
        transportStatusElement = <React.Fragment />;
    }
    return (
      <React.Fragment>
        <Header.Sender>{displayName}</Header.Sender>
        <Header.Status>{transportStatusElement}</Header.Status>
      </React.Fragment>
    );
  }

  render() {
    let { direction, error } = this.props.messageDetails.transportDetails;
    const messageStyle = direction === Direction.Outgoing ? this.props.outgoingMsgStyle : this.props.incomingMsgStyle;

    //Hack to simulate ChatJS response with attachment content types
    const bodyStyleConfig = {};
    if (
      this.props.isLatestMessage &&
      this.props.messageDetails.content.type === ContentType.MESSAGE_CONTENT_TYPE.INTERACTIVE_MESSAGE
    ) {
      bodyStyleConfig.hideDirectionArrow = true;
      bodyStyleConfig.removePadding = true;
    }
    let content, contentType;
    if (this.props.messageDetails.type === ATTACHMENT_MESSAGE) {
      //Use Attachments data as content if available
      //If an attachment message does not have this data, it means the upload was rejected
      if (this.props.messageDetails.Attachments && this.props.messageDetails.Attachments.length > 0) {
        content = this.props.messageDetails.Attachments[0];
        contentType = content.ContentType;
      } else {
        content = {
          AttachmentName: this.props.messageDetails.content.name,
        };
        contentType = this.props.messageDetails.content.type;
      }
    } else {
      content = this.props.messageDetails.content.data;
      contentType = this.props.messageDetails.content.type;
    }

    return (
      <React.Fragment>
        <Header>{this.renderHeader()}</Header>
        <Body direction={direction} messageStyle={messageStyle} {...bodyStyleConfig}>
          {this.renderContent(content, contentType)}
        </Body>
        {error && this.renderTransportError(error)}
      </React.Fragment>
    );
  }

  renderContent(content, contentType) {
    if (this.props.messageDetails.type === ATTACHMENT_MESSAGE) {
      return <AttachmentMessage content={content} downloadAttachment={this.props.mediaOperations.downloadAttachment} />;
    }
    let textContent = content;
    if (contentType === ContentType.MESSAGE_CONTENT_TYPE.INTERACTIVE_MESSAGE) {
      const { data, templateType } = JSON.parse(content);
      if (this.props.isLatestMessage) {
        return (
          <InteractiveMessage
            content={data.content}
            templateType={templateType}
            addMessage={this.props.mediaOperations.addMessage}
            textInputRef={this.props.textInputRef}
          />
        );
      }
      textContent = data.content.title;
    }
    return <PlainTextMessage content={textContent} />;
  }

  renderTransportError(error) {
    if (!error || !error.message) {
      return null;
    }
    return (
      <TransportErrorMessage>
        <span>{error.message}</span>
        {error.retry && this.renderRetryButton(error.retry)}
      </TransportErrorMessage>
    );
  }

  renderRetryButton(callback) {
    const onRetry = (e) => {
      e.preventDefault();
      callback();
    };

    return (
      <TransportErrorMessage.RetryButton href={"Retry"} tabIndex={0} onClick={onRetry} onKeyPress={onRetry}>
        Retry
      </TransportErrorMessage.RetryButton>
    );
  }
}

class PlainTextMessage extends PureComponent {
  render() {
    return (
      <Linkify className="chatFonts" properties={{ target: "_blank" }}>
        {this.props.content}
      </Linkify>
    );
  }
}

const ParticipantTypingBox = styled(MessageBox)`
  > ${Body}{
    display: inline-block;
    float: ${(props) => (props.direction === Direction.Outgoing ? "right" : "left")}
`;

export class ParticipantTyping extends PureComponent {
  render() {
    return (
      <ParticipantTypingBox direction={this.props.direction}>
        <Body direction={this.props.direction}>
          <TypingLoader color={this.props.direction === Direction.Outgoing ? "#fff" : "#000"} />
        </Body>
      </ParticipantTypingBox>
    );
  }
}

class AttachmentMessage extends PureComponent {
  downloadAttachment = (e) => {
    e.preventDefault();
    if (!this.props.content.AttachmentId) {
      return;
    }
    this.props.downloadAttachment(this.props.content.AttachmentId).then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", this.props.content.AttachmentName);
      link.click();
    });
  };

  renderContent() {
    if (this.props.content.Status === AttachmentStatus.APPROVED) {
      return (
        <a
          href={this.props.content.AttachmentName}
          onClick={this.downloadAttachment}
          onKeyPress={this.downloadAttachment}
        >
          {this.props.content.AttachmentName}
        </a>
      );
    }
    return this.props.content.AttachmentName;
  }

  render() {
    if (!this.props.content) {
      return;
    }

    return <div>{this.renderContent()}</div>;
  }
}
