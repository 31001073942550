// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import PT from "prop-types";
import { CONTACT_STATUS } from "../../constants/global";
import ChatTranscriptor from "./ChatTranscriptor";
import ChatComposer from "./ChatComposer";
import ChatActionBar from "./ChatActionBar";
import ChatEnd from "./ChatEndBar/ChatEnd";
import React, { Component } from "react";
import { Text } from "connect-core";
import styled from "styled-components";
import renderHTML from "react-render-html";
import ConfirmationModal from "./ChatActionBar/ConfirmationModal";
import { SESSION_CUSTOMER_INFO } from "../../utils/constants";
import clearSessionStorage, { getSessionItem } from "../../services/sessionManagerService";
import "../../styles/main.css";

const ChatWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
`;

const HeaderWrapperWhitelabel = styled.div`
  font-family: "Circular-USB", sans-serif;
  font-size: 18px;
  font-weight: 900;
  line-height: 26px;
  background: #235ae4;
  text-align: center;
  align-items: center;
  color: #fff;
  border-radius: 0 0 0 0;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: [first] 10% [line2] 70% [line3] 10% [line4] 10% [end];
  @media screen and (min-width: 600px) {
    border-radius: 8px 8px 0 0;
  }
  @media only screen and (min-width: 600px) {
    grid-template-columns: [first] 10% [line2] 80% [line3] 10% [end] !important;
  }
`;

const WelcomeText = styled(Text)`
  grid-column-start: 2;
  padding: 12px 0px;
  font-family: "Circular-USB", sans-serif;
  @media screen and (min-width: 600px) {
    font-size: 18px;
  }
`;

const MinimizeChat = styled.button`
  justify-self: anchor-center;
  grid-column-start: 1;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 16px;
  padding: 0px;
  height: 20px;
  width: 20px;
  vertical-align: middle;
  background: transparent;
  border: none;
  &:focus {
    outline-offset: 0.1rem !important;
    outline: 3px solid #61a0f7;
    border: none;
  }
`;

const ChatCloseButton = styled.button`
  justify-self: anchor-center;
  grid-column-start: 3;
  margin-bottom: 15px;
  margin-top: 15px;
	margin-right: 16px
  padding: 0px;
  height: 20px;
  width: 20px;
  vertical-align: middle;
  background: transparent;
  border: none;
  border-radius: 20px;
  &:focus {
    outline-offset: 0.1rem !important;
    outline: 3px solid #61a0f7;
    border: none;
  }
`;
const ChatSmallViewButton = styled.button`
  grid-column-start: 4;
  justify-self: start;
  display: relative;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 0px;
  height: 20px;
  width: 20px;
  vertical-align: middle;
  background: transparent;
  border: none;
  border-radius: 20px;
  @media only screen and (min-width: 600px) {
    display: none;
  }
`;
const SmallSizeIcon = styled.i`
  box-sizing: border-box;
  position: absolute;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 20px;
  height: 1.5px;
  border-left: 8px solid #ffffff;
  border-right: 8px solid #ffffff;
  &::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-top: 1.5px solid #ffffff;
    top: -2.4px;
  }
  &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-top: 1.5px solid #ffffff;
    top: -2.4px;
  }
  &::before {
    border-right: 1.5px solid #ffffff;
    left: -6px;
    transform: rotate(45deg);
  }
  &::after {
    border-left: 1.5px solid #ffffff;
    right: -6px;
    transform: rotate(-45deg);
  }
`;

const MaximizeChat = styled.i`
  box-sizing: border-box;
  position: relative;
  display: none;
  transform: scale(var(--ggs, 0.8));
  width: 14px;
  height: 14px;
  margin: 4px;
  color: white;
  box-shadow: -6px 6px 0 -4px, 6px -6px 0 -4px;
  &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 2px;
    background: white;
    transform: rotate(-45deg);
    bottom: 6px;
    left: -3px;
  }
`;
const DownArrow = styled.div`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-radius: 100px;
  &::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 1.5px solid white;
    border-right: 1.5px solid white;
    transform: rotate(45deg);
    left: 4px;
    top: 1px;
  }
`;

const CloseIcon = styled.div`
	box-sizing: border-box;
	position: relative;
	display: block;
	transform: scale(var(--ggs,1));
	width: 18.5px;
	height: 18.5px;
	border: 1.5px solid white;
	border-radius: 40px
   
	&&::before,&&::after {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 9px;
	height: 1.5px;
	background: currentColor;
	transform: rotate(45deg);
	border-radius: 5px;
	top: 45%;
    left: 21.5%;
	background-color: white;
   }
   &&::after {
	transform: rotate(-45deg)
   } 
`;
function isOMVChannelType() {
  const customerInfo = JSON.parse(getSessionItem(SESSION_CUSTOMER_INFO || "{}"));
  const channelId = ((customerInfo && customerInfo.channelId) || "").toLowerCase();
  const channelType = ((customerInfo && customerInfo.channelType) || "").toLowerCase();
  return channelType === "omv" || (channelId.endsWith("rpc") && channelType === "mobile");
}
function handleChatCloseHeaderButton() {
  window.setModalState();
  const chatEvent = new CustomEvent("CHAT_ACTIVITY", {
    bubbles: true,
    detail: { event: "CHAT_CLOSE_CLICK" },
  });
  window.dispatchEvent(chatEvent);
}

const defaultHeaderConfig = {
  isHTML: false,
  render: () => {
    const headerText = "Live agent chat";

    return (
      <>
        <HeaderWrapperWhitelabel className="headerWrapper">
          <MinimizeChat
            id="chat_widget_minimize_button"
            tabIndex={0}
            aria-label="minimize chat"
            onClick={() => window.connect.minimizeChat()}
          >
            <DownArrow />
          </MinimizeChat>
          <WelcomeText type={"h2"} className="chatFonts">
            {headerText}
          </WelcomeText>
          {!isOMVChannelType() && (
            <ChatCloseButton
              id="chat_widget_close_button"
              tabIndex={0}
              aria-label="close chat"
              type="button"
              onClick={handleChatCloseHeaderButton}
            >
              <CloseIcon alt="x" />
            </ChatCloseButton>
          )}
          <ChatSmallViewButton
            id="chat_widget_small_size_button"
            tabIndex={0}
            aria-label="resize chat"
            onClick={() => window.connect.resizeChat()}
          >
            <SmallSizeIcon id="chat_widget_small_view" alt="Resize" />
            <MaximizeChat id="chat_widget_maximize_view" alt="Maximize"></MaximizeChat>
          </ChatSmallViewButton>
        </HeaderWrapperWhitelabel>
      </>
    );
  },
};

Header.defaultProps = {
  headerConfig: {},
};

function Header({ headerConfig, onEndChat, setModalState }) {
  window.onEndChat = onEndChat;
  window.setModalState = setModalState;

  const config = Object.assign({}, defaultHeaderConfig, headerConfig);

  if (config.isHTML) {
    return renderHTML(config.render());
  } else {
    return config.render();
  }
}

const textInputRef = React.createRef();

export default class Chat extends Component {
  constructor(props) {
    super(props);
    console.log("Inside Chat Main Component", props);

    this.state = {
      transcript: [],
      typingParticipants: [],
      contactStatus: CONTACT_STATUS.DISCONNECTED,
      showModal: false,
    };
    this.updateTranscript = (transcript) => this.setState({ transcript });
    this.updateTypingParticipants = (typingParticipants) => this.setState({ typingParticipants });
    this.updateContactStatus = (contactStatus) => this.setState({ contactStatus });
    this.setModalState = this.setModalState.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  static propTypes = {
    chatSession: PT.object.isRequired,
    composerConfig: PT.object,
    onEnded: PT.func,
  };

  static defaultProps = {
    onEnded: () => {},
  };

  componentDidMount() {
    this.init(this.props.chatSession);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chatSession !== this.props.chatSession) {
      console.log("New chat session detected");
      this.cleanUp(prevProps.chatSession);
      this.init(this.props.chatSession);
    }
  }

  componentWillUnmount() {
    this.cleanUp(this.props.chatSession);
  }

  init(chatSession) {
    this.setState({ contactStatus: chatSession.contactStatus });
    chatSession.on("transcript-changed", this.updateTranscript);
    chatSession.on("typing-participants-changed", this.updateTypingParticipants);
    chatSession.on("contact-status-changed", this.updateContactStatus);
  }

  cleanUp(chatSession) {
    chatSession.off("transcript-changed", this.updateTranscript);
    chatSession.off("typing-participants-changed", this.updateTypingParticipants);
    chatSession.off("contact-status-changed", this.updateContactStatus);
  }

  async endChat() {
    console.log("Calling clearSessionStorage from Chat.js ");
    clearSessionStorage();
    const chatEndEvent = new CustomEvent("CHAT_ACTIVITY", {
      bubbles: true,
      detail: { event: "CHAT_END" },
    });
    window.dispatchEvent(chatEndEvent);
    try {
      if (this.props.chatSession && this.props.chatSession.contactStatus === CONTACT_STATUS.CONNECTED) {
        await this.props.chatSession.endChat();
      }
    } catch (e) {
      console.error("exception while ending chat session ", e);
    }
    this.props.onEnded();
    if (window.chat && window.chat.activityCallback) {
      window.chat.activityCallback({ event: "chat_end" });
    }
  }

  closeChat() {
    this.props.chatSession.closeChat();
  }

  setModalState() {
    this.setState({ showModal: !this.state.showModal });
  }

  handleModalClose() {
    const chatEvent = new CustomEvent("CHAT_ACTIVITY", {
      bubbles: true,
      detail: { event: "CHAT_KEEP_CHATTING" },
    });
    window.dispatchEvent(chatEvent);
    this.setState({ showModal: !this.state.showModal });
  }

  render() {
    const { chatSession, headerConfig, transcriptConfig, composerConfig, footerConfig } = this.props;
    console.log("Component rendered");
    return (
      <ChatWrapper>
        {(this.state.contactStatus === CONTACT_STATUS.CONNECTED ||
          this.state.contactStatus === CONTACT_STATUS.CONNECTING ||
          this.state.contactStatus === CONTACT_STATUS.ENDED) && (
          <Header headerConfig={headerConfig} onEndChat={() => this.endChat()} setModalState={this.setModalState} />
        )}

        <ChatTranscriptor
          tabIndex={0}
          loadPreviousTranscript={() => chatSession.loadPreviousTranscript()}
          addMessage={(data) => chatSession.addOutgoingMessage(data)}
          downloadAttachment={(attachmentId) => chatSession.downloadAttachment(attachmentId)}
          transcript={this.state.transcript}
          typingParticipants={this.state.typingParticipants}
          contactStatus={this.state.contactStatus}
          contactId={chatSession.contactId}
          transcriptConfig={transcriptConfig}
          textInputRef={textInputRef}
        />
        <ChatComposer
          contactStatus={this.state.contactStatus}
          contactId={chatSession.contactId}
          addMessage={(contactId, data) => {
            chatSession.addOutgoingMessage(data);
          }}
          addAttachment={(contactId, attachment) => chatSession.addOutgoingAttachment(attachment)}
          onTyping={() => chatSession.sendTypingEvent()}
          composerConfig={composerConfig}
          textInputRef={textInputRef}
        />
        {isOMVChannelType() && <ChatEnd></ChatEnd>}
        {
          <ChatActionBar
            onEndChat={() => this.endChat()}
            onClose={() => this.closeChat()}
            contactStatus={this.state.contactStatus}
            footerConfig={footerConfig}
            setModalState={this.setModalState}
          />
        }
        <ConfirmationModal
          show={this.state.showModal}
          handleModalClose={this.handleModalClose}
          handleChatClose={this.endChat}
        />
      </ChatWrapper>
    );
  }
}
