export const getHost = () => {
  return typeof window !== "undefined" ? window.location.host : "";
};
export const isLocal = process.env.NODE_ENV === "development";

const isOnEnv = () => {
  const host = getHost();
  const domain = host.split(/[.,\\/ -]/)[0].replace(/\d+|:/g, "");
  if (domain === "loansphereservicingdigital") return host.split(/[.,\\/ -]/)[1];
  if (domain === "www") return host.split(/[.,\\/ -]/)[1].replace(/\d+|:/g, "");
  return domain;
};

const formEndPointsByConvention = () => {
  switch (isOnEnv()) {
    case "dev":
    case "localhost":
    case "it":
    case "qa":
      return {
        apiGee: "it-api.usbank.com",
      };
    case "uat":
      return {
        apiGee: "alpha-api.usbank.com",
      };
    default:
      return {
        apiGee: "api.usbank.com",
      };
  }
};

const endpoints = typeof window !== "undefined" ? formEndPointsByConvention() : {};

const apiGeeDomain = `https://${endpoints.apiGee}`;
export const authApiGateWayEndpoint = `${apiGeeDomain}/external/customer/communications/connect/v1`;
export const unAuthApiGateWayEndpoint = `${apiGeeDomain}/interaction-management/customer/communications/connect/v1`;
